export const DEV_BASE_URL = 'http://localhost'
export const STAGING_BASE_URL = 'https://admin.staging-01.csoplatform.africa'
export const PRODUCTION_BASE_URL = 'https://admin.acsop.epic-africa.org'
export const PRODUCTION_BASE_URL2 = 'https://acsop.epic-africa.org'

export const STAGING_BASE_API_URL =
  'https://api.staging-01.csoplatform.africa/api/v1'
export const PRODUCTION_BASE_API_URL =
  'https://api.acsop.epic-africa.org/api/v1'
export const DEV_BASE_API_URL = 'http://localhost:8000/api/v1'
export const LANDING_PROD_PAGE_URL = 'https://landing.acsop.epic-africa.org/'
export const LANDING_STAGING_PAGE_URL = 'https://staging-01.csoplatform.africa/'
export let BASE_URL = ''
export let BASE_API_URL = ''
export let LANDING_URL = ''
export const location = window && window.location

export const hostname = `${location.protocol}//${location.hostname}`

switch (hostname) {
  case DEV_BASE_URL:
    BASE_URL = DEV_BASE_URL
    BASE_API_URL = DEV_BASE_API_URL
    LANDING_URL = LANDING_STAGING_PAGE_URL
    break
  case STAGING_BASE_URL:
    BASE_URL = STAGING_BASE_URL
    BASE_API_URL = STAGING_BASE_API_URL
    LANDING_URL = LANDING_STAGING_PAGE_URL

    break

  case PRODUCTION_BASE_URL:
  case PRODUCTION_BASE_URL2:
    BASE_URL = PRODUCTION_BASE_URL
    BASE_API_URL = PRODUCTION_BASE_API_URL
    LANDING_URL = LANDING_PROD_PAGE_URL

    break

  default:
    BASE_URL = `${DEV_BASE_URL}:${location.port}`
    BASE_API_URL = `${STAGING_BASE_API_URL}`
    break
}
